import React from "react";

function NN() {
  return (
    <div>
        404
    </div>
  );
}

export default NN;
